import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/innerpages.service";
import InnerPageService from "src/services/users/innerpage.service";

export const addInnerPages = createAsyncThunk(
  "innerpages/add",
  async (
    { item, itemId, category, image, title, description, isActive, type, slug },
    thunkAPI
  ) => {
    try {
      const data = await AuthService.addInnerPages(
        item,
        itemId,
        category,
        image,
        title,
        description,
        isActive,
        type,
        slug
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addCategoryInnerPages = createAsyncThunk(
  "navinneritems/add",
  async (
    { image, category, title, sequence, description, isActive, id, subtitle, slug, magazineUrl },
    thunkAPI
  ) => {
    try {
      const data = await AuthService.addCategoryInnerPages(
        image,
        category,
        title,
        sequence,
        description,
        isActive,
        id,
        subtitle,
        slug,
        magazineUrl
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateCategoryInnerPageData = createAsyncThunk(
  "innerpages/updateinnerinfo",
  async (
    { id, innerid, category, image, oldImage, title, description, sequence, subtitle, slug, magazineUrl },
    thunkAPI
  ) => {
    try {
      const data = await AuthService.updateCategoryInnerPageData(
        id,
        innerid,
        category,
        image,
        oldImage,
        title,
        description,
        sequence,
        subtitle,
        slug,
        magazineUrl
      );
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getInnerPage = createAsyncThunk(
  "innerpages/get",
  async (thunkAPI) => {
    try {
      const data = await AuthService.getInnerPage();
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateInnerPages = createAsyncThunk(
  "innerpages/update",
  async ({ id, isActive }, thunkAPI) => {
    try {
      const data = await AuthService.updateInnerPages(id, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateCategoryInnerPages = createAsyncThunk(
  "categoryinnerpages/update",
  async ({ itmId, id, isActive }, thunkAPI) => {
    try {
      const data = await AuthService.updateCategoryInnerPages(
        itmId,
        id,
        isActive
      );
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateInnerPagesData = createAsyncThunk(
  "innerpages/updateInfo",
  async (
    {
      id,
      item,
      itemId,
      category,
      image,
      oldImage,
      title,
      description,
      isActive,
      type,
      slug
    },
    thunkAPI
  ) => {
    try {
      const data = await AuthService.updateInnerPagesData(
        id,
        item,
        itemId,
        category,
        image,
        oldImage,
        title,
        description,
        isActive,
        type,
        slug
      );
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteInnerPages = createAsyncThunk(
  "innerpages/delete",
  async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteInnerPages(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteCategoryInnerItems = createAsyncThunk(
  "navinneritems/delete",
  async ({ id, itmId }, thunkAPI) => {
    try {
      const data = await AuthService.deleteCategoryInnerPages(id, itmId);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateHomeFrontPages = createAsyncThunk(
  "categoryinnerpages/update",
  async (homeparam, thunkAPI) => {
    try {
      const data = await AuthService.updateHomeFrontPages(homeparam);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addNavInnerSubItems = createAsyncThunk(
  "innersubarticle/add",
  async (
    {
      title,
      category,
      content,
      innerImage,
      price,
      url,
      type,
      description,
      id,
      innerId,
    },
    thunkAPI
  ) => {
    try {
      const data = await AuthService.addNavInnerSubItems(
        title,
        category,
        content,
        innerImage,
        price,
        url,
        type,
        description,
        id,
        innerId
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateContent = createAsyncThunk(
  "updatecontent/updateinnerpage",
  async ({ id, content, innerId }, thunkAPI) => {
    try {
      const data = await AuthService.updateContent(id, content, innerId);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteInnerSubItem = createAsyncThunk(
  "innertrends/updateInfo",
  async ({ itemId, id, innerId, oldImage }, thunkAPI) => {
    try {
      const data = await AuthService.deleteInnerSubItem(
        itemId,
        id,
        innerId,
        oldImage
      );
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const searchInnerPages = createAsyncThunk(
  "innerpages/search",
  async (query, thunkAPI) => {
    try {
      const data = await InnerPageService.searchInnerPages(query);
      console.log("Data res", data)
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const silentUpdateContent = createAsyncThunk(
  "innerpages/silentupdatecontent",
  async ({ id, content, innerId }, thunkAPI) => {
    try {
      const data = await AuthService.silentUpdateContent(id, content, innerId);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteInnerPageThumbnail = createAsyncThunk(
  "innerpages/deleteInnerPageThumbnail",
  async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteInnerPageThumbnail(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response?.data?.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, innerpages: null, searchResults: null };

const innerPagesSlice = createSlice({
  name: "innerpages",
  initialState,
  extraReducers: {
    [addInnerPages.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
    [addInnerPages.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
    [addCategoryInnerPages.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
    [addCategoryInnerPages.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
    [addNavInnerSubItems.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
    [addNavInnerSubItems.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
    [getInnerPage.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerpages = action.payload;
    },
    [getInnerPage.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
    [updateInnerPages.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerpages = action.payload;
    },
    [updateInnerPages.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
    [updateInnerPagesData.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerpages = action.payload;
    },
    [updateInnerPagesData.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
    [updateCategoryInnerPageData.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerpages = action.payload;
    },
    [updateCategoryInnerPageData.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
    [deleteInnerPages.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerpages = action.payload;
    },
    [deleteInnerPages.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
    [updateHomeFrontPages.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerpages = action.payload;
    },
    [updateHomeFrontPages.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
    [searchInnerPages.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerpages = action.payload;
      console.log("state.innerpages", action)
    },
    [searchInnerPages.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
    [silentUpdateContent.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerpages = action.payload;
    },
    [silentUpdateContent.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
    [deleteInnerPageThumbnail.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerpages = action.payload;
    },
    [deleteInnerPageThumbnail.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerpages = null;
    },
  },
});

const { reducer } = innerPagesSlice;
export default reducer;
